

  import {Component, Prop, Vue} from "vue-property-decorator";
  import {ILayoutItem} from "@/components/CustomForm/common";
  import {CustomFormFieldCreateOrUpdateDto, CustomFormType} from "@/api/appService";
  import FormItemControl from "@/components/CustomForm/Edit/FormItemControl.vue";

  @Component({name:'FormItem',
    components: {FormItemControl}
  })
  export default class FormItem extends Vue{

    @Prop({required:true})
    item!:ILayoutItem;

    @Prop({required:true})
    index!:number;

    @Prop({required:true})
    items!:ILayoutItem[];

    @Prop({required:true})
    dataDto!:any;

    @Prop({required:true})
    hostType!:CustomFormType;

    created(){
    }



    calcSpanSize(){
      const length = this.items.length;
      let result = 0;
      if(length === 0){
        return result;
      }
      if(24 % length === 0){
        result = 24/length;
      } else {
        const normalSize = parseInt((24 / length).toString()) ;
        if(this.index + 1 === length){
          result = 24 - (length -1) * normalSize;
        } else {
          result = normalSize
        }
      }
      return result;
    }

    getAttribute(attrName:string,item:ILayoutItem){
      const attributes = item.data.elementAttributes as any;

      let result = '';
      if(attributes){
        result = attributes[attrName]??'';
      } else {
        result = '';
      }
      this.$set(item.data.elementAttributes as object,attrName,result);
      return result;
    }


  }
