import AbstractValidator from "@/components/CustomForm/Validators/AbstractValidator";
import {CustomFormFieldCreateOrUpdateDto} from "@/api/appService";
import {IValidate} from "@/components/CustomForm/Validators/IValidate";
import ValidatorProperty from "@/components/CustomForm/Validators/ValidatorProperty";

export default class EmailValidator extends AbstractValidator implements IValidate{


  constructor(expression:string) {
    super(expression);
  }

  displayName(): string {
    return "邮箱";
  }

  isMatch(expression: string): boolean {
    return (expression === this.name());
  }

  toString(): string {
    return this.name();
  }

  name(): string {
    return "email";
  }



  properties(): ValidatorProperty[] {
    return [];
  }

  validate(value: any): string {
    if(typeof value === 'string' && value.length){
      if(/^([a-zA-Z0-9]+[-_.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[-_.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,6}$/.test(value)){
        return '';
      } else {
        return '不是有效的邮箱';
      }
    } else {
      return '';
    }
  }



}
