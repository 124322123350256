import AbstractValidator from "@/components/CustomForm/Validators/AbstractValidator";
import {CustomFormFieldCreateOrUpdateDto} from "@/api/appService";
import {IValidate} from "@/components/CustomForm/Validators/IValidate";
import ValidatorProperty from "@/components/CustomForm/Validators/ValidatorProperty";
import NumberValidator from "@/components/CustomForm/Validators/implements/NumberValidator";

export default class MaxValueValidator extends AbstractValidator implements IValidate{


  private maxValueProperty:ValidatorProperty;
  private numberValidator = new NumberValidator('number');


  constructor(expression: string) {
    super(expression);



    let propertyValue = '';
    let regex = new RegExp('([\\d]{1,20}(\\.\\d{1,20})?)');
    if(regex.test(expression)){
      propertyValue = regex.exec(expression)![0];
    }

    this.maxValueProperty = new ValidatorProperty('最大值',propertyValue);
    this.maxValueProperty.propertyValueValidate = (newValue:string | undefined) =>{
      if(!newValue){
        return ['值不能为空'];
      } else if(this.numberValidator.validate(newValue)){
        return ['必须是一个数值'];
      } else {
        return [];
      }
    }
  }

  displayName(): string {
    return "最大值";
  }

  name(): string {
    return "maxValue";
  }



  isMatch(expression: string): boolean {
    return (expression.startsWith(this.name()));
  }

  toString(): string {
    return `${this.name()}(${this.maxValue})`;
  }

  properties(): ValidatorProperty[] {
    return [this.maxValueProperty];
  }

  get maxValue(){
    return Number(this.maxValueProperty.propertyValue);
  }

  validate(value: any): string {
    if(typeof value !== 'string' && typeof value !=='number'){
      return '';
    }
    if(typeof value === 'string' && !value.length){
      return  '';
    }


    let numberValue:number = 0;
    if(typeof value === 'number'){
      numberValue = value;
    } else {
      const numberValidateResult = this.numberValidator.validate(value);
      if(numberValidateResult){
        return numberValidateResult;
      }
      numberValue = Number(value);
    }


    if(isNaN(numberValue)){
      return '不是一个有效的数值';
    } else if(numberValue > this.maxValue){
      return `值不能大于${this.maxValue}`;
    } else {
      return '';
    }



  }



}
