import {
  CustomFormDataSourceType,
  CustomFormFieldCreateOrUpdateDto,
  DataDictionaryDto,
  SelectListItem
} from "@/api/appService";

import api from '@/api/index'
import {v4 as uuidv4} from "uuid";
import ElementProperty from "@/components/CustomForm/Design/ControlEditor/ElementProperty";


export enum PropertyValueEditorType {
  'Input'='Input',
  'Dropdown'='Dropdown',
  'Radio' = 'Radio',
  'Group' = 'Group',
  'CustomValues' = 'CustomValues',
  'Switch' = 'Switch',
  'Validators' = 'Validators'
}



export function newGuid(){
  return uuidv4();
}
