import {IValidate} from "@/components/CustomForm/Validators/IValidate";
import {CustomFormFieldCreateOrUpdateDto} from "@/api/appService";

export  default abstract class AbstractValidator  {





  constructor(expression:string) {
    if(expression){
      if(!this.isMatch(expression))
        throw new Error(`expression is not ${this.displayName()}`)
    }

  }

  abstract displayName():string;

  abstract isMatch(expression: string):boolean;



}
