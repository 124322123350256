import {
  CustomFormFieldCreateOrUpdateDto,
  CustomFormLayoutDetailCreateOrUpdateDto,
  SelectListItem
} from "@/api/appService";
import {PropertyValueEditorType} from "@/components/CustomForm/ControlEditor/common";

type onValueChange = (newValue:string | undefined) => void;
type propertyValueValidate = (value:string | undefined) => string[];

export default class ValidatorProperty {

  private _propertyValue?:string;
  private _valueCheckErrors:string[]= [];


  propertyName!: string;
  show = true;
  valueEditor!:PropertyValueEditorType;



  onPropertyValueChange?:onValueChange;
  propertyValueValidate?:propertyValueValidate;


  constructor(propertyName: string,value:string) {
    this._propertyValue = value;
    this.propertyName = propertyName;
    this.valueEditor = PropertyValueEditorType.Input;


  }


  get valueCheckErrors():string[]{
    return this._valueCheckErrors;
  }

  get propertyValue(){
    return this._propertyValue;
  }



  set propertyValue(newValue){
    if(newValue!== this._propertyValue){
      const checkResult = this.checkNewValue(newValue);
      if(checkResult && checkResult.length){
        this._valueCheckErrors = checkResult;
      } else {
        this._valueCheckErrors = [];
      }
    }


    this._propertyValue = newValue;

  }


  private checkNewValue(newValue:string | undefined){
    return this.checkPropertyValue(newValue);
  }

  public isPropertyValueSuccess():boolean{
    let result = this.checkPropertyValue(this.propertyValue);
    return result.length === 0;
  }

  private checkPropertyValue(value:string | undefined){
    let result:string[] = [];
    if(!value){
      result.push('该属性值是必须的');
    }
    if(this.propertyValueValidate){
      let validationResult = this.propertyValueValidate(value);
      if(validationResult && validationResult.length){
        result = [...validationResult];
      }

    }

    return result;
  }

}
