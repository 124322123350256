

  import {Component, Prop, Vue, Watch} from "vue-property-decorator";
  import api from '@/api';
  import {CustomFormDto, CustomFormFieldDto, CustomFormLayoutDetailDto, CustomFormType} from "@/api/appService";
  import {createValidator, fieldNameTryAsCamelCase, ILayoutItem} from "@/components/CustomForm/common";
  import FormItem from "@/components/CustomForm/Edit/FormItem.vue";
  import {IValidate} from "@/components/CustomForm/Validators/IValidate";

  interface StandardDto {
    id: string | number;
    formId: string | number;
    extensionObject?: Object;
  }

  @Component({
    name: 'CustomFormEdit',
    components: {
      FormItem
    }
  })
  export default class CustomFormEdit extends Vue {


    @Prop({required: false, default: 0})
    formId!: number;


    @Prop({required: false, default: ''})
    hostType!: CustomFormType;

    @Prop({required: false, default: ''})
    dataId!: string | number;

    @Prop({required: false, default: false})
    useDataIdAsHostId!: boolean;

    @Prop({required: false})
    thirdData?: any;

    @Prop({required: true})
    getDataPromise!: Promise<StandardDto>;

    private customForm!: CustomFormDto;
    private dataDto: StandardDto = {id: 0, formId: 0, extensionObject: {}};
    private layoutDetails: ILayoutItem[] = [];

    private loading = true;
    private customFormId!: number;
    private formRules: any = {};

    async created() {
      if (this.thirdData && typeof this.thirdData === 'object') {
        this.dataDto = Object.assign({}, this.dataDto, this.thirdData)
      }

      if (this.formId) {
        this.customFormId = this.formId;
        await this.loadCustomForm();
      } else if (this.hostType) {
        const filter = {hostType: this.hostType, hostId: ''};
        if (this.useDataIdAsHostId) {

          filter.hostId = this.dataId.toString();
        }
        await api.customFormService.getAll(filter).then(res => {
          if (res.items && res.items.length) {
            this.dataDto.formId = res.items[0].id!;
            this.customFormId = res.items[0].id!;
          }
        })
        if (!this.customFormId) {
          this.$message.error('未找到对应的自定义表单');
          throw '未找到对应的自定义表单';
        }
        await this.loadCustomForm();
      } else {
        this.$message.error('组件参数不正确');
        throw '组件参数不正确'
      }

      if (this.dataId) {
        await this.getDataPromise.then(res => {
          this.dataDto = Object.assign({}, this.dataDto, res);
          this.dataDto.id = this.dataId.toString();
          this.loading = false;
        })
      } else {
        this.loading = false;
      }

    }

    private async loadCustomForm() {
      await api.customFormService.get({id: this.customFormId}).then(res => {
        this.customForm = res;
        this.updateFieldValidators();
        this.buildFormRule();
        const rootList = res.layout!.filter(s => s && !s.parentId);
        this.layoutDetails = this.buildLayoutDetailFromDto(rootList, res);

      });
    }

    private updateFieldValidators() {
      this.customForm.fields!.forEach(field => {
        let validators = field.fieldValidators ?? [];
        if (field.required) {
          if (!validators.some(s => s === 'required')) {
            validators = ['required'].concat(validators);
          }
        }
        field.fieldValidators = validators;


      });
    }


    private buildLayoutDetailFromDto(layouts: CustomFormLayoutDetailDto[], dto: CustomFormDto, layoutItem?: ILayoutItem) {

      if (!layouts || !layouts.length) {
        return [];
      }
      const result: ILayoutItem[] = [];
      layouts.forEach(item => {
        const newLayoutItem: ILayoutItem = {
          data: item,
          formControl: undefined
        };
        let control = dto.fields!.filter(s => s && s.layoutId === item.id);
        if (control && control.length) {
          newLayoutItem.formControl = control[0];
        }
        const children = dto.layout!.filter(s => s && s.parentId === item.id);
        newLayoutItem.children = this.buildLayoutDetailFromDto(children, dto, newLayoutItem);

        result.push(newLayoutItem);
      });

      return result;

    }

    @Watch('thirdData')
    onThirdDataChange(newValue: any) {
      if (newValue && typeof newValue === 'object') {
        this.dataDto = Object.assign({}, this.dataDto, newValue);
      }
    }

    public getRequestData() {
      return this.dataDto;
    }

    isProvince (value:string|undefined) {
      return (['province-city-area-town-control', 'ProvinceCityAreaTownControl', 'provinceCityAreaTownControl']).some(name => name === value)
    }

    private buildFormRule() {
      this.customForm.fields!.forEach(field => {
        let fieldRules = this.createValidationRules(field);
        let fieldName = fieldNameTryAsCamelCase(field);
        let propName = '';
        // const propName = field.isDynamic ? `extensionObject.${fieldName}` : fieldName;
        if (this.isProvince(field!.elementType)) {
          propName = field.isDynamic ? `extensionObject.${fieldName}.townId` : `${fieldName}.townId`;
        } else {
          propName = field.isDynamic ? `extensionObject.${fieldName}` : fieldName;
        }
        this.formRules[propName] = fieldRules;
      });

    }

    createValidationRules(control: CustomFormFieldDto): any[] {
      if (!control.fieldValidators || !control.fieldValidators.length) {
        return [];
      }
      let result: any[] = [];
      control.fieldValidators.forEach(expression => {
        result.push(this.createRule(expression));
      });

      return result;

    }

    createRule(expression: string) {

      let internalValidator: IValidate = createValidator(expression);
      const item = {
        trigger: ['blur', 'change'],
        validator: function (rules: any, value: any, callback: any) {


          let validationResult = internalValidator.validate(value);

          if (validationResult) {
            callback(new Error(validationResult));
          } else {
            callback();
          }
        }
      };
      return item;
    }

    validate(callback:Function) {
      (this.$refs.dataForm as any).validate(async (valid: boolean) => {
        callback(valid)
      })
    }

  }
