import AbstractValidator from "@/components/CustomForm/Validators/AbstractValidator";
import {CustomFormFieldCreateOrUpdateDto} from "@/api/appService";
import {IValidate} from "@/components/CustomForm/Validators/IValidate";
import ValidatorProperty from "@/components/CustomForm/Validators/ValidatorProperty";

export default class MaxLengthValidator extends AbstractValidator implements IValidate{


  private maxLengthProperty:ValidatorProperty;


  constructor(expression: string) {
    super(expression);

    let propertyValue = '';
    let regex = new RegExp('[\\d]{1,10}');
    if(regex.test(expression)){
      propertyValue = regex.exec(expression)![0];
    }

    this.maxLengthProperty = new ValidatorProperty('最大长度',propertyValue);
    this.maxLengthProperty.propertyValueValidate = (newValue:string | undefined) =>{
      if(!newValue){
        return ['值不能为空'];
      } else if(/^(\d{1,9})$/.test(newValue) === false){
        return ['必须是一个正整数'];
      } else {
        return [];
      }
    }
  }

  displayName(): string {
    return "字符最大长度";
  }

  name(): string {
    return "maxLength";
  }



  isMatch(expression: string): boolean {
    return (expression.startsWith(this.name()));
  }

  toString(): string {
    return `maxLength(${this.maxLengthProperty.propertyValue})`;
  }

  properties(): ValidatorProperty[] {
    return [this.maxLengthProperty];
  }

  get maxLength(){
    return Number(this.maxLengthProperty.propertyValue);
  }

  validate(value: any): string {
    if(typeof value === 'boolean'){
      return '';
    }
    if(typeof value === 'undefined' || value === null){
      return '';
    }
    let text = value.toString();
    if(text.length> this.maxLength){
      return `最多可以输入${this.maxLength}个字符`;
    }

    return '';


  }



}
