
import {Component, Prop, Vue, Model, Watch} from "vue-property-decorator";
import {AttachmentDto, AttachmentHostType} from "@/api/appService";
import api from '@/api/index'
import {UserModule} from "@/store/modules/user";
import {AppModule} from "@/store/modules/app";
import {interfaceTemplate} from "swagger-vue-codegen/dist/templates/template";

interface FileItem{
  id:number,
  name:string,
  url:string
}

@Component({name:"BatchUploadImage"})
export default class BatchUploadImage extends  Vue{

  @Model('change')readonly attachments!:number[];

  @Prop({required:true})
  hostId!:string | number;

  @Prop({required:true})
  hostType!:AttachmentHostType;


  private savedAttachments:number[] = [];
  private existsFileList:FileItem[] = [];

  get uploadParameter(){
    return {hostType:this.hostType,hostId:this.hostId};
  }

  get headers(){
    return {
      'Abp.OrganizationUnitId':UserModule.getOU?.id,
      'Abp.TenantId':AppModule.getTenantId,
      'Authorization':`Bearer ${UserModule.getToken}`,
      '.AspNetCore.Culture':'c=zh-Hans|uic=zh-Hans'
    }
  }

  @Watch('hostId')
  onHostIdChange(){
    this.loadExistsAttachments();
  }

  @Watch('hostType')
  onHostTypeChange(){
    this.loadExistsAttachments();
  }


  async created(){
    await this.loadExistsAttachments();
  }

  async loadExistsAttachments(){
    if(this.hostId&&this.hostType) {
      await api.attachmentService.getAttachments({hostId:this.hostId.toString(),hostType:this.hostType}).then(res=>{
        if(res){
          this.existsFileList = res.map(attachment=>{
            return {
              id:attachment.id!,
              url:attachment.url!,
              name:attachment.fileName!
            }
          });
          this.savedAttachments = res.map(attachment=>attachment.id!);
        } else {
          this.existsFileList = [];
          this.savedAttachments = [];
        }
        this.$emit('change',this.savedAttachments);
      })
    }

  }


  async handleSuccess(res: any, file: any) {
    if (res.success) {
      this.savedAttachments = [...this.savedAttachments,res.result.attachmentId];
      this.$emit('change',this.savedAttachments);
    }
  }

  async handleRemove(file: any, fileList: any) {
    let newValue:number[] = [];
    for(let i = 0;i<this.savedAttachments.length;i++){
      let id = this.savedAttachments[i];
      if(id!==file.id){
        newValue.push(id);
      }
    }
    this.savedAttachments = newValue;
    this.$emit('change',this.savedAttachments);
  }

}

