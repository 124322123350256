import AbstractValidator from "@/components/CustomForm/Validators/AbstractValidator";
import {CustomFormFieldCreateOrUpdateDto} from "@/api/appService";
import {IValidate} from "@/components/CustomForm/Validators/IValidate";
import ValidatorProperty from "@/components/CustomForm/Validators/ValidatorProperty";

export default class RequiredValidator extends AbstractValidator implements IValidate{


  constructor(expression:string) {
    super(expression);
  }

  displayName(): string {
    return "必填项";
  }

  isMatch(expression: string): boolean {
    return (expression === this.name());
  }

  toString(): string {
    return this.name();
  }

  properties(): ValidatorProperty[] {
    return [];
  }

  name(): string {
    return "required";
  }



  validate(value: any): string {
    if(typeof value === 'undefined' || value === null){
      return '必填';
    }

    if(typeof value === 'number'){
      return '';
    }

    if(typeof value === 'string' && !value.length){
      return '必填';
    }

    if(Array.isArray(value) && value.length === 0){
      return '必选'
    }


    return '';
  }



}
