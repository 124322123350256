import {
  CustomFormDataSourceType,
  CustomFormFieldCreateOrUpdateDto, CustomFormFieldDto,
  CustomFormLayoutDetailCreateOrUpdateDto,
  SelectListItem
} from "@/api/appService";
import api from "@/api";
import axiosInstance from "@/utils/request";
import {IValidate} from "@/components/CustomForm/Validators/IValidate";
import EmailValidator from "@/components/CustomForm/Validators/implements/EmailValidator";
import MaxLengthValidator from "@/components/CustomForm/Validators/implements/MaxLengthValidator";
import MaxValueValidator from "@/components/CustomForm/Validators/implements/MaxValueValidator";
import MinLengthValidator from "@/components/CustomForm/Validators/implements/MinLengthValidator";
import MinValueValidator from "@/components/CustomForm/Validators/implements/MinValueValidator";
import MobilePhoneValidator from "@/components/CustomForm/Validators/implements/MobilePhoneValidator";
import NumberValidator from "@/components/CustomForm/Validators/implements/NumberValidator";
import RequiredValidator from "@/components/CustomForm/Validators/implements/RequiredValidator";

export interface ILayoutItem {
  data: CustomFormLayoutDetailCreateOrUpdateDto;
  formControl?: CustomFormFieldCreateOrUpdateDto;
  children?: ILayoutItem[];
}





export function resolveSelectOptions(control:CustomFormFieldCreateOrUpdateDto):Promise<SelectListItem[]>{
  return new Promise<SelectListItem[]>((resolve,reject)=>{
    if(control.dataSource!.dataSourceType === CustomFormDataSourceType.CustomValues){
      const result = control.chooseValues!.map(value=>{
        return {text:value.displayName,value:value.id}  as SelectListItem;
      });
      resolve(result);
    } else if(control.dataSource!.dataSourceType === CustomFormDataSourceType.DataDictionary){
      if(control.dataSource!.dataSource){
        api.dataDictionary.getDataDictionaryListByKey({key:control.dataSource!.dataSource}).then(res=>{
          const result = res.items!.map(dictionary=>{return {text:dictionary.displayName,value:dictionary.id} as SelectListItem});
          resolve(result);
        });
      } else {
        resolve([]);
      }
    } else if (control.dataSource!.dataSourceType === CustomFormDataSourceType.Enum){
      if(control.dataSource!.dataSource){
        api.enumService.getValues({typeName:control.dataSource!.dataSource}).then(res=>{
          const result = res.map(enumValue=>{return {text:enumValue.text,value:enumValue.value} as SelectListItem});
          resolve(result);
        });
      } else {
        resolve([]);
      }

    } else if(control.dataSource!.dataSourceType === CustomFormDataSourceType.Api){
      if(control.dataSource!.dataSource!.startsWith('/')){
        axiosInstance.get(control.dataSource!.dataSource!).then(res=>{
          let result = res as any;
          let items = [];
          if(result.hasOwnProperty('items')){
            items = result.items;
          } else {
            items = result.items;
          }
          const bindValue = control.dataSource!.bindValue!;
          const bindDisplayName = control.dataSource!.bindDisplayName!;
          const list = items?.map((item:any)=>{return {value:item[bindValue],text:item[bindDisplayName]}  as SelectListItem});
          resolve(list);
        });
      } else {
        resolve([]);
      }
    }


  });
}

export function createValidator(expression:string):IValidate{
  let validator:IValidate ;

  if(expression.startsWith('email')){
    validator = new EmailValidator(expression);
  } else if(expression.startsWith('maxLength')){
    validator = new MaxLengthValidator(expression);
  } else if(expression.startsWith('maxValue')){
    validator = new MaxValueValidator(expression);
  } else if(expression.startsWith('minLength')){
    validator = new MinLengthValidator(expression);
  } else if(expression.startsWith('minValue')){
    validator = new MinValueValidator(expression);
  } else if(expression.startsWith('mobilePhone')){
    validator = new MobilePhoneValidator(expression);
  } else if(expression.startsWith('number')){
    validator = new NumberValidator(expression);
  } else if(expression.startsWith('required')){
    validator = new RequiredValidator(expression);
  } else {
    throw new Error(`没有设置${expression}的验证器`)
  }

  return validator;
}

export function fieldNameTryAsCamelCase(field:CustomFormFieldDto | CustomFormFieldCreateOrUpdateDto){
  let result:string;
  if(field.isDynamic){
    result = field.fieldName!;
  } else {
    result = field.fieldName!.substr(0,1).toLowerCase() + field.fieldName!.substr(1);
  }
  return result;
}
