import AbstractValidator from "@/components/CustomForm/Validators/AbstractValidator";
import {CustomFormFieldCreateOrUpdateDto} from "@/api/appService";
import {IValidate} from "@/components/CustomForm/Validators/IValidate";
import ValidatorProperty from "@/components/CustomForm/Validators/ValidatorProperty";

export default class MinLengthValidator extends AbstractValidator implements IValidate{


  private minLengthProperty:ValidatorProperty;


  constructor(expression: string) {
    super(expression);

    let propertyValue = '';
    let regex = new RegExp('[\\d]{1,10}');
    if(regex.test(expression)){
      propertyValue = regex.exec(expression)![0];
    }

    this.minLengthProperty = new ValidatorProperty('最小长度',propertyValue);
    this.minLengthProperty.propertyValueValidate = (newValue:string | undefined) =>{
      if(!newValue){
        return ['值不能为空'];
      } else if(/^(\d{1,9})$/.test(newValue) === false){
        return ['必须是一个正整数'];
      } else {
        return [];
      }
    }
  }

  name(): string {
    return "minLength";
  }



  displayName(): string {
    return "字符最短长度";
  }

  isMatch(expression: string): boolean {
    return (expression.startsWith(this.name()));
  }

  toString(): string {
    return `${this.name()}(${this.minLengthProperty.propertyValue})`;
  }

  properties(): ValidatorProperty[] {
    return [this.minLengthProperty];
  }

  get minLength(){
    return Number(this.minLengthProperty.propertyValue);
  }

  validate(value: any): string {
    if(typeof value === 'boolean'){
      return '';
    }
    if(typeof value === 'undefined' || value === null){
      return '';
    }
    let text = value.toString();
    if(text.length < this.minLength){
      return `最少输入${this.minLength}个字符`;
    }

    return '';


  }



}
